import { useEffect } from "react";
import { useState } from "react";
import { fetchAPI } from "../../utils/utils";
import { applyTimeZone } from "../interface/assistencia/LlistaAssistencies";
import moment from "moment";
import './NotificationLog.css';

function NotificationRecepients({ notifications, displayName, scheduled=false }) {
    const [isCollapsed, setIsCollapsed] = useState(true);
    const displayNames = scheduled ? (notifications?.[0]?.target?.map(displayName) || []) : notifications.map(n => n.target).map(displayName);
    const initialDisplayNames = displayNames.slice(0,4);
    const remainingDisplayNames = displayNames.slice(4);

    return (
        <>
            {isCollapsed ? initialDisplayNames.join(', ') : displayNames.join(', ')}
            {remainingDisplayNames.length > 0 && 
                <a
                    onClick={() => setIsCollapsed(!isCollapsed)}
                    style={{
                        color: 'blue',
                        cursor: 'pointer',
                        textDecoration: 'underline',
                    }}
                >
                    {isCollapsed ? ` i ${remainingDisplayNames.length} més` : ' mostrar menys'}
                </a>
            }
        </>
    )
}

function NotificationTaps({ notifications, castellersInfo }) {
    const [taps, setTaps] = useState([]);

    const totalReceptors = notifications.length

    const realReceptors = notifications
        .map(n => n.target)
        .filter(id => id in castellersInfo)
        .map(id => castellersInfo[id])
        .filter(c => c?.has_notifications)
        .length

    const lostReceptors = totalReceptors - realReceptors

    useEffect(() => {
        const notificationId = notifications?.[0]?.notification_id;
        if (!notificationId) return;

        fetchAPI(`/notification_taps/${notificationId}`, data => {
            setTaps(data);
        });
    }, [notifications?.[0]?.notification_id]);

    // Put '-' if notification is sent before the release date of the change
    const isBefore = notifications[0].createdAt < '2024-08-17T22:15:00Z';
    if (isBefore) return <span>-</span>

    const tapsMotes = taps
        .map(t => t.user_id)
        .filter(id => id in castellersInfo)
        .map(id => castellersInfo[id])
        .map(c => c.mote || `${c.nom} ${c.cognom?.[0]}.`)
        .filter(mote => mote)
        .join(', ');

    return (
        <div title={tapsMotes} id={notifications?.[0]?.notification_id + '-taps'}>
            <a
                href={`#${notifications?.[0]?.notification_id}-taps`}
                onClick={() => alert(`L'han obert:\n\n${tapsMotes}`)}
                style={{
                    color: 'darkblue',
                    cursor: 'pointer',
                    textDecoration: 'underline',
                }}
            >
                {taps.length} / {realReceptors}
            </a>
            {lostReceptors > 0 && <span style={{ color: 'red' }}> + {lostReceptors}</span>}
        </div>
    )
}

function NotificationLog({ castellersInfo, notificationsState, socket }) {
    const [notifications, setNotifications] = useState([]);
    const [scheduledNotifications, setScheduledNotifications] = useState([]);
    const [loadNNotifications, setLoadNNotifications] = useState(5);
    const [loadNScheduledNotifications, setLoadNScheduledNotifications] = useState(5);
    const [cancellationStatus, setCancellationStatus] = useState({});
    const [tapCounts, setTapCounts] = useState({});

    useEffect(() => {
        fetchAPI('/notifications', newData => {
            setNotifications(prevData => {
                if (prevData.length > 0) {
                    return newData.map(item => {
                        const isExistingItem = prevData.some(prevItem => prevItem.id === item.id);
                        return isExistingItem ? item : { ...item, new: true };
                    });
                } else {
                    return newData;
                }
            })
        });

        fetchAPI('/scheduled-notifications', newData => {
            console.log(newData);
            setScheduledNotifications(newData);
        });
    }, [
        notificationsState === 'done'
    ]);

    useEffect(() => {
        socket.on('.scheduled_notification_cancelled', (response) => {
            setCancellationStatus(prev => ({
                ...prev,
                [response.notificationId]: response.success ? 'success' : 'failed'
            }));
            if (response.success) {
                setScheduledNotifications(prev => prev.filter(n => n.message.notification_id !== response.notificationId));
            }
        });

        socket.on('.scheduled_notification_scheduled', (response) => {
            fetchAPI('/scheduled-notifications', newData => {
                setScheduledNotifications(newData);
            }); 
        });

        return () => {
            socket.off('.scheduled_notification_cancelled');
            socket.off('.scheduled_notification_scheduled');
        };
    }, [socket]);

    useEffect(() => {
        const id = setTimeout(() => {
            setNotifications(prevData => {
                return prevData.map(item => ({ ...item, new: false }));
            })
        }, 5000);

        return () => clearTimeout(id);
    }, [
        notifications.length
    ])

    const format_text = (text, id) => {
        const nom = id in castellersInfo ?
            castellersInfo[id].nom
            : ''

        const sobrenom = id in castellersInfo ?
            castellersInfo[id].mote ? castellersInfo[id].mote :
            castellersInfo[id].nom ? castellersInfo[id].nom :
            ''
        : ''

        return text
            .replaceAll(' {nom}', nom !== '' ? ` ${nom}` : '')
            .replaceAll('{nom}', nom !== '' ? nom : '')
            .replaceAll(' {sobrenom}', sobrenom !== '' ? ` ${sobrenom}` : '')
            .replaceAll('{sobrenom}', sobrenom !== '' ? sobrenom : '')
    }

    const displayName = id => castellersInfo[id] ?
        (castellersInfo[id].mote || `${castellersInfo[id].nom} ${castellersInfo[id].cognom[0]}.`) :
        ''

    const groupedNotificationsByNotificationId = notifications
        .reduce((acc, notification) => {
            if (notification.notification_id in acc) {
                acc[notification.notification_id].push(notification);
            } else {
                acc[notification.notification_id] = [notification];
            }
            return acc;
        }, {});

    const dataEnviament = notification => {
        // Fa N segons
        const diffTime = Math.abs(moment() - moment(notification.createdAt));
        const diffSeconds = Math.floor(diffTime / 1000);
        if (diffSeconds < 60) return `Fa ${diffSeconds} segons`;
        // Fa N minuts
        const diffMinutes = Math.floor(diffSeconds / 60);
        if (diffMinutes < 60) return `Fa ${diffMinutes} minuts`;
        // Fa N hores i M minuts
        const diffHours = Math.floor(diffMinutes / 60);
        const diffMinutes2 = diffMinutes % 60;
        if (diffHours < 24) return `Fa ${diffHours} hores i ${diffMinutes2} minuts`;
        
        return moment(notification.createdAt).format('HH:mm DD/MM/YYYY')
    }

    const dataScheduled = notification => {
        const scheduledTime = moment(notification.scheduledTime);
        const now = moment();
        const diff = scheduledTime.diff(now);

        const duration = moment.duration(diff);
        const seconds = duration.seconds();
        const minutes = duration.minutes();
        const hours = duration.hours();

        const hourFormat = scheduledTime.format('HH:mm');
        const standardFormat = scheduledTime.format('HH:mm DD/MM/YYYY');

        if (seconds < 60) return `En ${seconds} segons`;
        if (minutes === 0) return `En ${seconds} segons (${hourFormat})`;
        if (minutes === 1) return `En 1 minut i ${seconds} segons (${hourFormat})`;
        if (minutes < 60) return `En ${minutes} minuts (${hourFormat})`;
        if (hours === 1) return `En 1 hora i ${minutes} minuts (${standardFormat})`;
        if (hours < 24) return `En ${hours} hores i ${minutes} minuts (${standardFormat})`;
        return standardFormat;
    }

    const formatMessage = notification => {
        return {
            id: notification.message.notification_id,
            title: notification.message.title,
            body: notification.message.body,
            author: notification.message.author,
            target: notification.userIds,
            scheduledTime: notification.scheduledTime,
        }
    }

    const formatSelectedDay = (notification) => {
        const selectedDay = notification?.message?.data?.selectedDay;
        if (!selectedDay) return '-';

        const [day, month, year] = selectedDay.split('-');
        const formattedDay = day.padStart(2, '0');
        const formattedMonth = (parseInt(month) + 1).toString().padStart(2, '0');

        return `${formattedDay}/${formattedMonth}/${year}`;
    }

    return (
        <div>
            {scheduledNotifications.filter(notification => moment(notification.scheduledTime).isAfter(moment())).length > 0 && (
                <div>
                    <h2>Notificacions programades</h2>
                    <div style={{ width: '100%', overflowX: 'auto' }}>
                        <table className="notifications-log">
                            <thead>
                                <tr>
                                    <th>Enviador</th>
                                    <th>Receptors</th>
                                    <th>Títol (exemple)</th>
                                    <th>Missatge (exemple)</th>
                                    <th>Data programada</th>
                                    <th>Data activitat</th>
                                    <th>Accions</th>
                                </tr>
                            </thead>
                            <tbody style={{ fontSize: '0.8rem' }}>
                                {scheduledNotifications
                                    .filter(notification => moment(notification.scheduledTime).isAfter(moment()))
                                    .sort((a, b) => moment(a.scheduledTime) - moment(b.scheduledTime))
                                    .slice(0, loadNScheduledNotifications)
                                    .map(notification => (
                                        <tr key={formatMessage(notification).id}>
                                            <td>{displayName(notification.message.author)}</td>
                                            <td>
                                                <NotificationRecepients
                                                    notifications={[formatMessage(notification)]}
                                                    displayName={displayName}
                                                    scheduled={true}
                                                />
                                            </td>
                                            <td>{format_text(formatMessage(notification).title, notification.userIds[0])}</td>
                                            <td>{format_text(formatMessage(notification).body, notification.userIds[0])}</td>
                                            <td>{dataScheduled(notification)}</td>
                                            <td>{formatSelectedDay(notification)}</td>
                                            <td>
                                                {cancellationStatus[notification.message.notification_id] === 'success' ? (
                                                    <span>Cancel·lada</span>
                                                ) : cancellationStatus[notification.message.notification_id] === 'failed' ? (
                                                    <span>Error en cancel·lar</span>
                                                ) : (
                                                    <button
                                                        onClick={() => {
                                                            const confirmCancel = window.confirm('Estàs segur que vols cancel·lar aquesta notificació programada?');
                                                            if (confirmCancel) {
                                                                socket.emit('.cancel_scheduled_notification', notification.message.notification_id);
                                                                setCancellationStatus(prev => ({
                                                                    ...prev,
                                                                    [notification.message.notification_id]: 'pending'
                                                                }));
                                                            }
                                                        }}
                                                        disabled={cancellationStatus[notification.message.notification_id] === 'pending'}
                                                    >
                                                        {cancellationStatus[notification.message.notification_id] === 'pending' ? 'Cancel·lant...' : 'Cancel·lar'}
                                                    </button>
                                                )}
                                            </td>
                                        </tr>
                                    ))}
                            </tbody>
                        </table>
                    </div>
                    {
                        loadNScheduledNotifications < scheduledNotifications.length &&
                        <button
                            onClick={() => setLoadNScheduledNotifications(loadNScheduledNotifications + 5)}
                            style={{
                                width: '100%',
                                marginTop: 10,
                            }}
                        >
                            Carrega'n més
                        </button>
                    }
                </div>
            )}

            <h2>Historial de notificacions</h2>

            <div
                style={{
                    width: '100%',
                    overflowX: 'auto',
                }}
            >
                <table
                    className="notifications-log"
                >
                    <thead>
                        <tr>
                            {/* <th>Id</th> */}
                            {/* <th>Usuari</th> */}
                            <th>Enviador</th>
                            <th>Receptors</th>
                            <th>Títol (exemple)</th>
                            <th>Missatge (exemple)</th>
                            <th>Data d'enviament</th>
                            <th>L'han obert</th>
                        </tr>
                    </thead>
                    <tbody
                        style={{
                            fontSize: '0.8rem',
                        }}
                    >
                        {
                            Object.values(groupedNotificationsByNotificationId)
                                .sort((a, b) => applyTimeZone(a[0].createdAt) > applyTimeZone(b[0].createdAt) ? -1 : 1)
                                .slice(0, loadNNotifications)
                                .map(notifications => (
                                    <tr
                                        key={notifications[0].notification_id}
                                        className={`notification ${notifications[0].new ? 'new' : ''}`}
                                    >
                                        {/* <td>{notifications[0].notification_id}</td> */}
                                        {/* <td>{notifications[0].target}</td> */}
                                        <td>{displayName(notifications[0].author)}</td>
                                        <td>
                                            <NotificationRecepients
                                                notifications={notifications}
                                                displayName={displayName}
                                            />
                                        </td>
                                        <td>{notifications[0].title}</td>
                                        <td>{notifications[0].body}</td>
                                        <td>
                                            {dataEnviament(notifications[0])}
                                        </td>
                                        <td>
                                            <NotificationTaps
                                                notifications={notifications}
                                                taps={tapCounts[notifications[0].notification_id] || 0}
                                                castellersInfo={castellersInfo}
                                            />
                                        </td>
                                    </tr>
                                ))
                        }
                    </tbody>
                </table>
            </div>

            {
                loadNNotifications < Object.values(groupedNotificationsByNotificationId).length &&
                <button
                    onClick={() => setLoadNNotifications(loadNNotifications + 5)}
                    style={{
                        width: '100%',
                        marginTop: 10,
                    }}
                >
                    Carrega'n més
                </button>
            }
        </div>
    )
}

export default NotificationLog;