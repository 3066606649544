import React, { useState, useEffect } from 'react';
import { fetchAPI } from '../../../utils/utils';
import moment from 'moment';

function AssistenciesLog({ eventId, castellersInfo }) {
    const [assistenciesLog, setAssistenciesLog] = useState([]);
    const [visibleLogs, setVisibleLogs] = useState(10);

    useEffect(() => {
        fetchAPI(`/log_assistencies_event/${eventId}`, (response) => {
            setAssistenciesLog(response.data);
        });
    }, [eventId]);

    const formatAssistenciaEmoji = (assistencia) => {
        if (parseInt(assistencia) === 0) return '❌';
        if (parseInt(assistencia) === 1) return '✅';
        if (parseInt(assistencia) === 2) return '📍';
        return '❓';
    }

    const getCastellerName = (id) => {
        const casteller = castellersInfo?.[id];
        if (!casteller) return 'Anònim';
        if ('mote' in casteller && casteller.mote) return casteller.mote;
        return `${casteller.nom} ${casteller.cognom}`;
    }

    const loadMoreLogs = () => {
        setVisibleLogs(prevVisibleLogs => prevVisibleLogs + 10);
    }

    return (
        <div style={{ padding: '20px', backgroundColor: '#f9f9f9', borderRadius: '8px' }}>
            <h2 style={{ textAlign: 'center', color: '#333' }}>Historial d'assistències</h2>
            <ul style={{ listStyleType: 'none', padding: 0 }}>
                {assistenciesLog
                    .sort((a, b) => a.id > b.id ? -1 : 1)
                    .slice(0, visibleLogs)
                    .map((log) => (
                        <li key={log.id} style={{ padding: '10px', borderBottom: '1px solid #ddd', display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                            <span style={{ fontSize: '1.5em' }}>{formatAssistenciaEmoji(log['assistència'])}</span>
                            <span style={{ fontWeight: 'bold', color: '#555' }}>{getCastellerName(log['casteller-id'])}</span>
                            <span style={{ color: '#999' }}>{moment(log.updatedAt).format('DD/MM/YYYY HH:mm:ss')}</span>
                        </li>
                    ))}
            </ul>
            {visibleLogs < assistenciesLog.length && (
                <button onClick={loadMoreLogs} style={{ display: 'block', margin: '20px auto', padding: '10px 20px', backgroundColor: '#007bff', color: '#fff', border: 'none', borderRadius: '5px', cursor: 'pointer' }}>
                    Carrega'n més
                </button>
            )}
        </div>
    );
}

export default AssistenciesLog;
