import React, { useState, useEffect } from 'react';
import { Bar } from 'react-chartjs-2';
import { fetchAPI } from '../../../utils/utils';
import { Chart as ChartJS, BarElement, CategoryScale, LinearScale, Title, Tooltip, Legend } from 'chart.js';

ChartJS.register(BarElement, CategoryScale, LinearScale, Title, Tooltip, Legend);

function AssistenciesGraph({ eventId }) {
    const [assistenciesLog, setAssistenciesLog] = useState([]);

    useEffect(() => {
        fetchAPI(`/log_assistencies_event/${eventId}`, (response) => {
            setAssistenciesLog(response.data);
        });
    }, [eventId]);

    const processData = (data) => {
        const bins = {};
        data.forEach(log => {
            const date = new Date(log.updatedAt);
            const dateString = date.toISOString().split('T')[0]; // YYYY-MM-DD format
            if (!bins[dateString]) {
                bins[dateString] = { 'No vinc': 0, 'Vinc': 0, 'Fitxat': 0, 'No confirmat': 0 };
            }
            const assistencia = parseInt(log.assistència);
            if (assistencia === 0) bins[dateString]['No vinc']++;
            else if (assistencia === 1) bins[dateString]['Vinc']++;
            else if (assistencia === 2) bins[dateString]['Fitxat']++;
            else bins[dateString]['No confirmat']++;
        });

        const labels = Object.keys(bins).sort();
        const noVincData = labels.map(label => bins[label]['No vinc']);
        const vincData = labels.map(label => bins[label]['Vinc']);
        const fitxatData = labels.map(label => bins[label]['Fitxat']);
        const noConfirmatData = labels.map(label => bins[label]['No confirmat']);

        return {
            labels,
            datasets: [
                {
                    label: 'No vinc',
                    data: noVincData,
                    backgroundColor: 'red',
                    stack: 'stack1',
                },
                {
                    label: 'Vinc',
                    data: vincData,
                    backgroundColor: 'green',
                    stack: 'stack1',
                },
                // {
                //     label: 'Fitxat',
                //     data: fitxatData,
                //     backgroundColor: 'blue',
                //     stack: 'stack1',
                // },
                // {
                //     label: 'No confirmat',
                //     data: noConfirmatData,
                //     backgroundColor: 'gray',
                //     stack: 'stack1',
                // },
            ],
        };
    };

    const chartData = processData(assistenciesLog);

    const options = {
        plugins: {
            title: {
                display: false,
                text: 'Gràfic d\'assistències',
            },
            tooltip: {
                mode: 'index',
                intersect: false,
            },
            legend: {
                position: 'top',
            },
        },
        responsive: true,
        scales: {
            x: {
                stacked: true,
            },
            y: {
                stacked: true,
            },
        },
    };

    return (
        <div>
            <Bar data={chartData} options={options} />
        </div>
    );
}

export default AssistenciesGraph;
